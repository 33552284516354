import React from "react";
import NavLinks from "./nav-links";

export default function Navigation() {
  return (
    <nav id="navigation">
      <NavLinks />
    </nav>
  );
}
