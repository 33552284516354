import React from "react";

export default function About() {
  return (
    <div className="section">
      <div id="about">
        <h1 id="about-name">Daniel Cabrera</h1>
        <p id="about-description">Software Engineering</p>
      </div>
    </div>
  );
}
